import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import "simplebar-react/dist/simplebar.min.css";
import Intercom from "components/Intercom";
import useLogRocket from "hooks/useLogRocket";
import NotFound from "pages/Etc/NotFound";
import CircleTopics from "pages/Etc/CircleTopics/CircleTopics";

function App() {
  useLogRocket();

  return (
    <div className="App">
      <div className="body">
        <Intercom />
        <main className="with-animation">
          <Navbar />
          <Routes>
            <Route path="/" element={<CircleTopics />} />
            <Route path="/not-found" element={<NotFound />} />
            <Route path="*" element={<Navigate replace to="/not-found" />} />
          </Routes>
        </main>
      </div>
    </div>
  );
}

export default App;
