import React, { useEffect } from "react";
import { useIntercom } from "react-use-intercom";

const Intercom = () => {
  const { update } = useIntercom();

  useEffect(() => {
    update({ hideDefaultLauncher: false });
  }, [update]);

  return <></>;
};

export default Intercom;
