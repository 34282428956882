import { configureStore } from "@reduxjs/toolkit";
import mainSlice from "./mainSlice";
import circleTopicSlice from "./circleTopicSlice";

const store = configureStore({
  reducer: {
    main: mainSlice,
    circle: circleTopicSlice,
  },
});

export default store;
