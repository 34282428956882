import { JESSICA_URL } from "api";
import React from "react";
import Jessica from "assets/images/others/banner-jessica.png";

const CircleBanner = () => {
  return (
    <div className="w-full relative">
      <div className="w-full h-[200px] sm:h-[160px] md:h-[120px] lg:h-[160px] xl:h-[200px] rounded-[20px] md:rounded-[30px] overflow-hidden bg-white relative">
        <div className="absolute bg-[#0CE755] blur-[300px] xl:blur-[370px] w-1/2 h-[150%] top-1/2 left-[20%]"></div>
      </div>
      <div className="z-[10] absolute px-4 sm:px-8 lg:px-12 xl:px-[100px] flex justify-between items-end bottom-0 left-0 w-full gap-4 lg:gap-6 xl:gap-12">
        <img
          src={Jessica}
          alt="jessica"
          className="w-[120px] lg:w-[160px] xl:w-[200px] h-auto flex-shrink-0 max-400:hidden"
        />
        <div className="flex items-center justify-around md:justify-between flex-1 md:gap-4 flex-col md:flex-row h-[200px] sm:h-[160px] md:h-auto py-4 md:py-0">
          <div className="flex flex-col sm:w-[360px] lg:w-[400px] xl:w-[480px] font-figtree text-black md:min-h-[120px] lg:min-h-[160px] xl:min-h-[200px] justify-center gap-1 lg:gap-2">
            <span className="text-[20px] lg:text-[26px] xl:text-[34px] tracking-[0.25px] text-center md:text-left">
              Unlock Your Content Potential
            </span>
            <span className="text-[14px] lg:text-[16px] xl:text-[20px] lg:leading-[160%] tracking-[0.15px] font-[500] max-400:text-center">
              Let Jessica turn your ideas into captivating content. Ready to
              make an impact?
            </span>
          </div>
          <a
            href={`${JESSICA_URL}/?utm_source=topic-discovery&utm_medium=LP&utm_campaign=banner`}
            className="w-[160px] lg:w-[200px] xl:w-[250px] bg-primary-main rounded-[40px] py-1 lg:py-2 px-4 lg:px-5 text-white font-figtree text-[15px] font-[700] leading-[173%] tracking-[0.46px] text-center"
            style={{
              boxShadow:
                "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
            }}
          >
            Get Started
          </a>
        </div>
      </div>
    </div>
  );
};

export default CircleBanner;
