import React, { useEffect, useState } from "react";
import Arc from "assets/images/others/half.svg";
import { CIRCLE_TOPICS_SOURCES, cutLinkedin } from "Utils";
import ChevronBottomIcon from "assets/images/others/chevron-down.svg";
import LinkedinIcon from "assets/images/others/linkedin.svg";
import TwitterIcon from "assets/images/others/TwitterLogo.png";
import { useLocation, useNavigate } from "react-router-dom";

const OPTIONS = [
  {
    name: "Linkedin",
    icon: LinkedinIcon,
    value: CIRCLE_TOPICS_SOURCES.linkedin,
    recommended: true,
  },
  {
    name: "X",
    icon: TwitterIcon,
    value: CIRCLE_TOPICS_SOURCES.twitter,
    recommended: false,
  },
  {
    name: "Website",
    icon: null,
    value: CIRCLE_TOPICS_SOURCES.website,
    recommended: false,
  },
];

const CircleInput = ({ onSubmit, hidden }) => {
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);
  const [option, setOption] = useState("linkedin");
  const navigate = useNavigate();
  const disabled = value.trim().length === 0;
  const selectedOption = OPTIONS.find((o) => o.value === option);
  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  const handleSubmit = (e) => {
    e?.preventDefault?.();
    if (disabled) return;
    // onSubmit?.(value, option);
    let formattedValue = value;
    if (option === "linkedin") {
      formattedValue = `https://www.linkedin.com${
        value.includes("company/") ? "/company" : "/in"
      }/${cutLinkedin(value)}`;
    }
    if (option === "twitter") {
      formattedValue = `https://x.com/${cutLinkedin(value)}`;
    }
    const targetUrl = `/?${option}=${formattedValue}`;
    if (location.pathname + location.search === targetUrl) {
      window.location.reload();
    } else {
      navigate(targetUrl);
    }
  };

  const location = useLocation();

  useEffect(() => {
    const queries = new URLSearchParams(location.search);
    if (queries.get("linkedin")) {
      setValue(
        `https://www.linkedin.com${
          queries.get("linkedin").includes("company/") ? "/company" : "/in"
        }/${cutLinkedin(queries.get("linkedin"))}`
      );
      onSubmit?.(queries.get("linkedin"), "linkedin");
    } else if (queries.get("twitter")) {
      setOption("twitter");
      setValue(`https://x.com/${cutLinkedin(queries.get("twitter"))}`);
      onSubmit?.(queries.get("twitter"), "twitter");
    } else if (queries.get("website")) {
      setOption("website");
      setValue(queries.get("website"));
      onSubmit?.(queries.get("website"), "website");
    }
  }, [location.search, onSubmit]);

  return (
    <div
      className={`${
        hidden ? "hidden" : "flex"
      } w-full flex-col items-center max-w-full gap-6 sm:gap-14`}
    >
      <div className="flex flex-col gap-2.5 w-full sm:w-auto">
        <div className="flex flex-col gap-1">
          <div className="flex sm:w-[524px] flex-1 sm:flex-none">
            <form onSubmit={handleSubmit} className="flex-1 flex">
              <input
                type="text"
                id="linkedin"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                placeholder={
                  option === CIRCLE_TOPICS_SOURCES.linkedin
                    ? "Linkedin URL"
                    : option === CIRCLE_TOPICS_SOURCES.twitter
                    ? "X URL"
                    : "Website URL"
                }
                className="border border-other-outlined-border border-r-0 outline-none rounded-l-[30px] px-3 py-4 placeholder:text-text-secondary font-figtree text-[16px] leading-[150%] tracking-[0.15px] bg-gradient1 flex-1 min-w-0 w-0 sm:w-full"
              />
            </form>
            <div className="relative flex-shrink-0">
              <button
                className="w-[159px] px-3 py-4 flex items-center justify-between rounded-r-[30px] border border-other-outlined-border bg-white bg-gradient1 gap-5"
                onClick={handleClick}
              >
                <div className="flex items-center gap-1 text-text-primary font-figtree text-[16px] leading-[150%] tracking-[0.15px]">
                  <span>{selectedOption.name}</span>
                  {selectedOption.icon && (
                    <img
                      src={selectedOption.icon}
                      alt="icon"
                      className="flex-shrink-0 w-6 h-6"
                    />
                  )}
                </div>
                <img
                  src={ChevronBottomIcon}
                  alt="down"
                  className="flex-shrink-0 w-6 h-6"
                />
              </button>
              {selectedOption.recommended && (
                <div className="absolute top-0 left-0 -translate-y-1/2 py-0.5 px-2 flex-shrink- rounded-[16px] bg-[#FF9538] font-figtree text-[10px] leading-[140%] tracking-[0.15px]">
                  Recommended
                </div>
              )}
              {open && (
                <div className="absolute top-full cursor-pointer z-10 rounded-b-[30px] overflow-hidden">
                  {OPTIONS.map((o, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        if (option !== o.value) {
                          setOption(o.value);
                          setValue("");
                        }
                        setOpen(false);
                      }}
                      className="w-[134px] py-4 px-4 flex flex-col items-start justify-start bg-gradient1"
                    >
                      {o.recommended && (
                        <div className="py-0.5 px-2 flex-shrink- rounded-[16px] bg-[#FF9538] font-figtree text-[10px] leading-[140%] tracking-[0.15px]">
                          Recommended
                        </div>
                      )}
                      <div className="flex items-center gap-1 text-text-primary font-figtree text-[16px] leading-[150%] tracking-[0.15px]">
                        <span>{o.name}</span>
                        {o.icon && (
                          <img
                            src={o.icon}
                            alt="icon"
                            className="flex-shrink-0 w-6 h-6"
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="text-left pl-3.5 text-text-secondary font-figtree text-[12px] leading-[20px] tracking-[0.4px]">
            {option === "linkedin" &&
              `https://www.linkedin.com/in/{your_profile_id}`}
            {option === "twitter" && `https://x.com/{your_profile_id}`}
            {option === "website" && `Example: https://www.google.com/`}
          </div>
        </div>
        <div className="text-left pl-3.5 font-figtree text-[16px] leading-[24px] tracking-[0.15px] text-text-secondary">
          <span>{"Example: "}</span>
          {option === "linkedin" && (
            <>
              <span
                className="link interact text-cta-blue"
                onClick={() => {
                  setValue("https://www.linkedin.com/in/williamhgates");
                }}
              >
                {"Bill Gates"}
              </span>
              ,{" "}
              <span
                className="link interact text-cta-blue"
                onClick={() => setValue("https://www.linkedin.com/in/dangelo")}
              >
                {"Adam D'Angelo"}
              </span>
            </>
          )}
          {option === "twitter" && (
            <>
              <span
                className="link interact text-cta-blue"
                onClick={() => {
                  setValue("https://x.com/billgates");
                }}
              >
                {"Bill Gates"}
              </span>
              ,{" "}
              <span
                className="link interact text-cta-blue"
                onClick={() => setValue("https://x.com/adamdangelo")}
              >
                {"Adam D'Angelo"}
              </span>
            </>
          )}
          {option === "website" && (
            <>
              <span
                className="link interact text-cta-blue"
                onClick={() => {
                  setValue("https://www.google.com/");
                }}
              >
                {"Google"}
              </span>
            </>
          )}
        </div>
      </div>

      <div className="max-w-full relative flex sm:block flex-col-reverse items-center justify-center gap-2">
        <img src={Arc} alt="arc" className="w-[1169px]" />
        <button
          className={`sm:absolute sm:top-0 sm:left-1/2 sm:-translate-x-1/2 rounded-[20px] py-2 px-5  ${
            disabled
              ? "text-action-disabled bg-action-disabled-background"
              : "text-white bg-primary-main"
          } font-figtree text-[15px] font-[700] leading-[173%] tracking-[0.46px]`}
          disabled={disabled}
          onClick={handleSubmit}
        >
          Start Exploring
        </button>
      </div>
    </div>
  );
};

export default CircleInput;
