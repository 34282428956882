import React from "react";
import { ReactComponent as Logo } from "assets/images/logo.svg";
// import { ReactComponent as DropdownIcon } from "assets/images/dropdownIcon.svg";
import "./style.css";
import { JESSICA_URL } from "api";

const Navbar = () => {
  return (
    <nav>
      <div className="links">
        <a href={`${JESSICA_URL}/?utm_source=topic-discovery&utm_medium=LP&utm_campaign=logo`}>
          <Logo />
        </a>
      </div>
      <div className="links">
        <div className="navigation"></div>
      </div>
    </nav>
  );
};

export default Navbar;
