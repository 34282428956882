import { useEffect } from "react";
import LogRocket from "logrocket";

// Custom hook for initializing LogRocket
const useLogRocket = () => {
  useEffect(() => {
    const originsToExclude = [
      'localhost:3000',
      'stg.circle.q-ueue.ai'
    ];

    if (!originsToExclude.includes(window.location.origin)) {
      LogRocket.init('iraia3/topic-discovery-tool');
    }
  }, []);
};

export default useLogRocket;
