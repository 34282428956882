import React, { useRef, useState } from "react";
import CloseIcon from "assets/images/post-edit/x-close.svg";
import useUserInfo from "hooks/useUserInfo";
import { useDispatch, useSelector } from "react-redux";
import AnimationCard from "./AnimationCard";
import { capitalize, cutLinkedin, showErrorMessage } from "Utils";
import axios from "axios";
import { JESSICA_URL, SERVER_URL } from "api";
import { setRegisterEmail } from "store/mainSlice";
import useCookie from "hooks/useCookie";
import Jessica from "assets/images/book.png";
import Avatar from "components/Avatar";

const CircleStartPopup = ({ setOpenPopup }) => {
  const dispatch = useDispatch();
  const { profile, isAuth } = useUserInfo();
  const { fullName, mainTopic, picture } = useSelector((state) => state.circle);
  const [emailValue, setEmailValue] = useState("");
  const popupRef = useRef(null);
  const closePopup = (e) => {
    if (popupRef.current && !popupRef.current.contains(e.target))
      setOpenPopup(false);
  };
  const queries = new URLSearchParams(window.location.search);
  const isCompanyLinkedin = (() => {
    return (
      !!queries.get("linkedin") && queries.get("linkedin").includes("company/")
    );
  })();
  const firstName =
    profile.firstName || isCompanyLinkedin ? "You" : fullName.split(" ")[0];

  const neededInfo = !isAuth && !profile.linkedin;
  const neededLinkedin =
    !profile.linkedin && (!queries.get("linkedin") || isCompanyLinkedin);
  const [wait, setWait] = useState(false);
  const [linkedinValue, setLinkedinValue] = useState("");
  const { setCookieEmail } = useCookie();
  const handleSubmit = async (e) => {
    e?.preventDefault?.();
    if (wait) return;
    const linkedin = neededLinkedin
      ? linkedinValue
      : profile.linkedin || queries.get("linkedin");
    const email = neededInfo ? emailValue : profile.email;
    if (!email?.trim()) {
      showErrorMessage("Email is required");
      return;
    }
    if (!linkedin?.trim()) {
      showErrorMessage("linkedin is required");
      return;
    }
    setWait(true);
    const cutLinkedinUrl = cutLinkedin(linkedin);
    let newLinkedinProfile = null;
    if (!isAuth) {
      try {
        const response = await axios.get(
          `${SERVER_URL}/services/linkedin/${cutLinkedinUrl}`
        );
        const {
          officialFirstName,
          officialLastName,
          company,
          picture,
          headline,
        } = response.data;

        newLinkedinProfile = {
          firstName: officialFirstName,
          officialFirstName,
          officialLastName,
          company,
          picture,
          email: email.trim(),
          linkedin: cutLinkedin,
          headline,
          // title
        };
        // rest of the validation and submission logic here
      } catch (error) {
        console.error(error);
        if (error.response?.status === 404) {
          showErrorMessage("LinkedIn URL is invalid");
        } else
          showErrorMessage(
            error.response?.data?.message || "Server Error, please try again."
          );
        // handle the error, display a message to the user, etc.
        setWait(false);
        return;
      }
    }
    try {
      const response = await axios.post(
        `${SERVER_URL}/interview-sessions/create`,
        {
          expertDetails: {
            firstName,
            email,
            linkedinProfile: newLinkedinProfile || {
              officialFirstName: profile.officialFirstName,
              officialLastName: profile.officialLastName,
              company: profile.company,
              picture: profile.picture,
              headline: profile.headline,
            },
            linkedin,
          },
          interviewDetails: {
            questionOrTopic: mainTopic,
            scheduleTime: {
              year: new Date().getFullYear(),
              month: new Date().getMonth(),
              day: new Date().getDate(),
              hour: -1,
              minute: -1,
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
            recurring: false,
          },
        },
        { timeout: 100 * 1000 }
      );
      dispatch(setRegisterEmail(email));
      setCookieEmail(email);
      window.location.href = `${JESSICA_URL}/meeting/${response.data.interviewId}?utm_source=topic-discovery&utm_medium=LP&utm_campaign=question`;
    } catch (error) {
      console.log(error);
      showErrorMessage(
        error.response?.data?.message || "Internal server error"
      );
    }
    setWait(false);
  };
  return (
    <div
      className="fixed w-full h-full overflow-hidden bg-[#131313aa] flex items-center justify-center z-[100] top-0 left-0 scroll-dark"
      onClick={closePopup}
    >
      <div className="max-h-full overflow-auto p-4 w-full scroll-dark">
        <div
          className="relative left-1/2 -translate-x-1/2 rounded-[30px] overflow-hidden bg-off-white-light  w-[726px] max-w-full grid grid-cols-1 items-center gap-4 md:gap-8 py-6 pt-10 px-4 max-400:px-2 sm:px-6"
          style={{
            boxShadow:
              "0px 6.157434463500977px 88.47000122070312px 0px #959da524",
          }}
          ref={popupRef}
        >
          <div className="absolute right-4 top-4 cursor-pointer z-[11]">
            <img
              src={CloseIcon}
              alt="close"
              onClick={() => setOpenPopup(false)}
            />
          </div>
          <div className="text-center md:text-left text-black-bg font-eighties-it max-400:text-[22px] text-[28px] sm:text-[36px] leading-[103%] flex justify-center gap-2 md:gap-4 lg:gap-10 items-center">
            <div className="flex flex-shrink-0 items-center">
              <Avatar src={picture} alt="user" className="z-[1] flex-shrink-0 size-14 sm:size-[74px] bg-off-white-light" />
              <img src={Jessica} alt="jessica" className="w-[40px] sm:w-[54.47px] h-auto flex-shrink-0 -ml-3"/>
            </div>
            <span>{capitalize(fullName)} talk about</span>
          </div>
          <div className="rounded-[30px] bg-off-white-super-light flex flex-col p-4 items-center gap-4">
            <div className="w-full flex flex-col gap-6 items-start">
              <span className="text-black-bg font-figtree text-[16px] font-[500] leading-[225%]">
                Chosen Topic:
              </span>
              <div className="relative w-full">
                <div className="absolute top-0 left-0 -translate-y-[70%] px-2.5 py-1 font-roboto text-text-primary tex-[13px] leading-[138%] tracking-[0.16%] rounded-[16px] bg-purple-lavender">
                  Custom
                </div>
                <div className="py-3 px-4 rounded-[30px] text-text-primary font-figtree text-[16px] leading-[150%] tracking-[0.15px] bg-off-white-base w-full overflow-hidden">
                  {mainTopic}
                </div>
              </div>
            </div>
            {neededInfo && (
              <form
                onSubmit={handleSubmit}
                className="w-full flex flex-col gap-2"
              >
                <input
                  type="text"
                  value={emailValue}
                  onChange={(e) => setEmailValue(e.target.value)}
                  id="email"
                  placeholder="Enter your email to get started"
                  className="px-3 py-4 w-full placeholder:text-text-secondary font-figtree text-[16px] leading-[150%] tracking-[0.15px] rounded-[30px] border border-primary-main"
                />
                {neededLinkedin && (
                  <input
                    type="text"
                    value={linkedinValue}
                    onChange={(e) => setLinkedinValue(e.target.value)}
                    id="linkedin"
                    placeholder="Enter your Linkedin to get started"
                    className="px-3 py-4 w-full placeholder:text-text-secondary font-figtree text-[16px] leading-[150%] tracking-[0.15px] rounded-[30px] border border-primary-main"
                  />
                )}
              </form>
            )}
            <div className="w-full flex items-center justify-center flex-wrap gap-3">
              <button
                className="flex items-center justify-center gap-2 w-[258px] max-w-full rounded-[40px] text-white font-inter text-[16px] font-semibold leading-[24px] text-center py-2 px-4 bg-primary-main interact"
                onClick={handleSubmit}
                disabled={wait}
              >
                {wait ? "Wait..." : "Let's Talk (It's Free!)"}
              </button>
            </div>
          </div>
          <div className="text-black font-figtree text-[16px] sm:text-[22px] font-[600] leading-[130%] pl-3 max-400:pl-0 flex md:hidden gap-1 items-baseline">
            <span>What can you expect?</span>
            <span className="text-[12px]">(video demo)</span>
          </div>
          <div className="h-full flex-col gap-4 lg:gap-6 flex">
            <AnimationCard className="flex flex-1" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CircleStartPopup;
