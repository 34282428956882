import { BASE_SERVER_URL, SERVER_URL } from "api";
import axios from "axios";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAuth,
  setBillingInfo,
  setIsAuth,
  setIsCheckingSubscription,
  setProfile,
} from "store/mainSlice";
import { AUTH_STEP, cutLinkedin, isValidEmail, showErrorMessage } from "Utils";

const useUserInfo = () => {
  const dispatch = useDispatch();
  const { profile, billingInfo, status, registerEmail } = useSelector(
    (state) => state.main
  );
  const { email, linkedin, firstName, interviews } = profile;
  const {
    isCheckingAuth,
    isCheckingActivity,
    isCheckingSubscription,
    isAuth,
    isSignedUp,
    isCheckingInterview,
    isCheckingAudience,
  } = status;
  const isChecking =
    isCheckingAuth ||
    isCheckingActivity ||
    isCheckingSubscription ||
    isCheckingInterview;
  const isSubscribed = isAuth && !!billingInfo && !billingInfo.isCanceled;
  const isCanceled = billingInfo?.isCanceled;

  // const fetchLinkedinProfile = async (linkedin) => {
  //   try {
  //     const response = await axios.get(
  //       `${SERVER_URL}/services/linkedin/${cutLinkedin(linkedin)}`
  //     );

  //     const {
  //       officialFirstName,
  //       officialLastName,
  //       company,
  //       picture,
  //       headline,
  //     } = response.data;

  //     return {
  //       // firstName,
  //       officialFirstName,
  //       officialLastName,
  //       company,
  //       picture,
  //       // email,
  //       linkedin: cutLinkedin(linkedin),
  //       headline,
  //       // title
  //     };
  //   } catch (error) {
  //     console.log(error);
  //     if (error.response?.status === 404) {
  //       showErrorMessage("LinkedIn URL is invalid");
  //     } else {
  //       showErrorMessage(
  //         error.response?.data?.message || "Server Error, please try again."
  //       );
  //     }
  //     return null;
  //   }
  // };

  const signUp = async ({ password }, callback = () => {}) => {
    if (password.trim().length === 0) {
      showErrorMessage("Password is required.");
      return false;
    }
    const completedLinkedin = `https://www.linkedin.com/in/${cutLinkedin(
      linkedin.trim()
    )}/`;
    try {
      await axios.post(`${BASE_SERVER_URL}/auth/sign-up`, {
        email,
        name: firstName,
        linkedinProfileUrl: completedLinkedin,
        linkedInProfileUrl: completedLinkedin,
        password,
      });
      dispatch(setIsAuth(true));
      dispatch(setAuth(AUTH_STEP.no_auth));
      callback();
      return true;
    } catch (error) {
      console.log(error);
      showErrorMessage(
        error.response?.data?.message ||
          "Sign up failed. Please check your details and try again"
      );
    }
  };

  const fetchUserInfo = async () => {
    try {
      const response = await axios.get(
        `${BASE_SERVER_URL}/api/v2/users/assistant/profile`
      );
      const profileResponse = response.data.profile;
      dispatch(setIsAuth(true));
      let newLinkedin =
        profileResponse.linkedinProfileUrl ||
        profileResponse.linkedInProfileUrl ||
        profileResponse.linkedin ||
        linkedin ||
        "";
      let linkedinProfile = {};
      if (newLinkedin) {
        newLinkedin = cutLinkedin(newLinkedin);
        const response = await axios.get(
          `${SERVER_URL}/services/linkedin/${newLinkedin}`
        );
        linkedinProfile = response.data;
      } else {
        console.log("The user doesn't have linkedin profile link");
      }
      dispatch(
        setProfile({
          ...linkedinProfile,
          firstName: profileResponse.name,
          email: profileResponse.email,
          picture: linkedinProfile.picture || profile.picture,
          linkedin: newLinkedin,
          linkedinConnected: !!profileResponse.linkedinConnected,
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(setIsAuth(false));
    }
  };

  const signIn = async ({ email, password }, callback = () => {}) => {
    if (email.trim().length === 0) {
      showErrorMessage("Email is required.");
      return false;
    }
    if (!isValidEmail(email.trim())) {
      showErrorMessage("Please enter a valid email address.");
      return false;
    }
    if (password.trim().length === 0) {
      showErrorMessage("Email is required.");
      return false;
    }
    try {
      //   const result = await fetchLinkedinProfile(linkedin);
      //   if (!result) return;
      await axios.post(`${BASE_SERVER_URL}/auth/sign-in`, {
        email: email.trim(),
        password,
      });
      await fetchUserInfo();
      dispatch(setAuth(AUTH_STEP.no_auth));
      callback();
      //   dispatch(setProfile({ ...result, email, firstName: name }));
      return true;
    } catch (error) {
      console.log(error);
      showErrorMessage(
        error.response?.data?.message ||
          "Sign in failed. Please check your details and try again"
      );
    }
  };

  const signOut = async (callback = () => {}) => {
    try {
      //   const result = await fetchLinkedinProfile(linkedin);
      //   if (!result) return;
      await axios.post(`${BASE_SERVER_URL}/auth/logout`);
      //   dispatch(setProfile({ ...result, email, firstName: name }));
      dispatch(setIsAuth(false));
      callback();
      return true;
    } catch (error) {
      console.log(error);
      showErrorMessage(
        error.response?.data?.message ||
          "Sign out failed. Please check your details and try again"
      );
    }
  };

  const fetchUserBilling = useCallback(async () => {
    if (!isAuth) {
      dispatch(setIsCheckingSubscription(false));
      dispatch(setBillingInfo(null));
      return;
    }
    if (isSubscribed) {
      dispatch(setIsCheckingSubscription(false));
      return;
    }
    dispatch(setIsCheckingSubscription(true));
    try {
      const response = await axios.get(`${SERVER_URL}/billing/info`);
      dispatch(setBillingInfo(response.data));
    } catch (error) {
      console.log(error);
      dispatch(setBillingInfo(null));
    }
    dispatch(setIsCheckingSubscription(false));
  }, [dispatch, isAuth, isSubscribed]);

  return {
    isChecking,
    isCheckingAuth,
    isCheckingSubscription,
    isCheckingActivity,
    isAuth,
    isSignedUp: isAuth || isSignedUp,
    isSubscribed,
    isCanceled,
    isCheckingAudience,
    billingInfo,
    profile: {
      firstName: firstName?.split().shift(),
      fullName: `${profile.officialFirstName || firstName} ${
        profile.officialLastName
      }`,
      officialFirstName: profile.officialFirstName,
      officialLastName: profile.officialLastName,
      company: profile.company,
      email: profile.email,
      picture: profile.picture,
      linkedin: profile.linkedin,
      headline: profile.headline,
      linkedinConnected: !!profile.linkedinConnected,
      personalized: !!profile.personalized,
      audience: profile.audience,
      interviews,
    },
    registerEmail,
    interviews,
    fetchUserBilling,
    signIn,
    signUp,
    signOut,
  };
};

export default useUserInfo;
