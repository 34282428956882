import React from "react";
import Jessica from "assets/images/others/jessica-orange-red.png";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="w-full flex-1 flex flex-col justify-center items-center bg-off-white-light p-4">
      <img src={Jessica} alt="jessica" className="w-[200px] h-auto" />
      <div className="font-figtree text-[150px] leading-[150%]">404</div>
      <div className="flex flex-col items-center justify-center gap-[22px]">
        <div className="font-figtree text-[24px] text-center leading-[150%]">
          Ooops, we can't find this page
        </div>
        <Link
          to={`/`}
          style={{ fontFeatureSettings: "'clig' off, 'liga' off" }}
          className="rounded-full bg-primary-main text-white font-[700] text-[15px] px-[22px] py-2"
        >
          Go to Homepage
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
